<template>
  <CCard no-header>
    <CCardBody>
      <h3>
        <CIcon name="cil-pencil" /> {{ operationName }} Coupon
      </h3>
      <CAlert v-if="message != null" :color="alertType">
        {{ message }}
      </CAlert>
      <CRow>
        <CCol sm="6">
          <CInput label="Coupon Name" type="text" placeholder="Coupon Name"
            :disabled="this.$route.params.id != this.emptyGuid" v-model="coupon.name"></CInput>
        </CCol>
      </CRow>

      <CRow>
        <CCol>
          <label>Valid from</label>
          <ejs-datetimepicker v-model="coupon.validFrom" :format="$dateFormatInput"
            :timeFormat="$dateFormatInput.split(' ').length > 1 ? `${$dateFormatInput.split(' ')[1]} ${$dateFormatInput.split(' ')[2]}` : $dateFormatInput.split(' ')[1]"></ejs-datetimepicker>
        </CCol>
        <CCol>
          <label>Valid to</label>
          <ejs-datetimepicker v-model="coupon.validTo" :format="$dateFormatInput"
            :timeFormat="$dateFormatInput.split(' ').length > 1 ? `${$dateFormatInput.split(' ')[1]} ${$dateFormatInput.split(' ')[2]}` : $dateFormatInput.split(' ')[1]"></ejs-datetimepicker>
        </CCol>
      </CRow>
      <h3 style="margin-top:20px!important">
        <CIcon name="cilMoney" /> Discounts
      </h3>

      <CRow>
        <CCol style="margin-left:1vw!important" col="3" v-for="(currency, index) in currencies">
          <CRow>
            <CInputCheckbox :id="'currency-' + currency.currencyCode">
            </CInputCheckbox>
            <h6>{{ currency.name }}</h6>
          </CRow>
          <CRow>
            <label :for="'absoluteDiscount-' + currency.currencyCode"> Absolute Discount</label>
            <input class="form-control" min="0" :id="'absoluteDiscount-' + currency.currencyCode" type="number" />
          </CRow>
          <CRow>
            <label :for="'relativeDiscount-' + currency.currencyCode">Relative Discount %</label>
            <input class="form-control" min="0" :id="'relativeDiscount-' + currency.currencyCode" type="number" />
          </CRow>
        </CCol>
      </CRow>
      <h3 style="margin-top:20px!important">
        <CIcon name="cilFactory" /> Commission Payment
      </h3>

      <CRow style="margin-top:20px!important">
        <CCol sm="6">
          <CSelect label="For Company" :value.sync="coupon.companyId" :plain="true"
            :disabled="this.$route.params.id != this.emptyGuid" :options="companies">
          </CSelect>
        </CCol>

        <CCol sm="6">
          <CInput textarea="true" label="Company Comission %" :rows="9" type="number" placeholder="Company Comission %"
            v-model="coupon.companyComission"></CInput>
        </CCol>

      </CRow>

      <h3 style="margin-top:20px!important">
        <CIcon name="cilFactory" /> Aplicable categories
      </h3>

      <CRow style="padding-top: 10px">
        <CCol sm="3">
          <CInput textarea="true" label="Add a category" :rows="9" type="text" placeholder="Add a category"
            v-model="category"></CInput>
        </CCol>
        <CCol col="3">
          <CButton color="success" class="btn" style="margin-top: 3.5vh !important" @click="pushRelatedCategory()">
            <CIcon name="cilSmilePlus"></CIcon>
            Add Category
          </CButton>
        </CCol>
      </CRow>

      <li v-for="item in coupon.couponRelatedCategories">
        {{
          item.categoryName
        }}
        <CButton style="margin-left: 10px" @click="eliminateRelatedCategory(item.categoryName)">
          <CIcon name="cilXCircle" />
        </CButton>
      </li>
      <h3 style="margin-top:20px!important">
        <CIcon name="cilFactory" /> Integration
      </h3>

      
      <CRow style="margin-top:20px!important">
        <CCol sm="6">
          <CSelect label="For Company" :value.sync="coupon.integrationId" :plain="true" :options="integrations">
          </CSelect>
        </CCol>

      </CRow>
      <CRow>
        <CCol col="9"></CCol>
        <CCol class="d-flex justify-content-end">
          <CButton color="secondary" @click="back">
            <CIcon name="cil-list" /> Back to the list
          </CButton>
          <CButton color="primary" @click="save()" style="margin-left: 15px">
            <CIcon name="cil-save" /> Save
          </CButton>
        </CCol>
      </CRow>
    </CCardBody>
  </CCard>
</template>

<script>
import axios from "axios";
import { DateTimePickerComponent } from '@syncfusion/ej2-vue-calendars';
export default {
  name: "CreateOrUpdateCoupon",
  components: {
    'ejs-datetimepicker': DateTimePickerComponent,
  },
  data: () => {
    return {
      operationName: "Create New",
      category: null,
      allRelatedCategories: [],
      partnerId: localStorage.getItem("partner_id"),
      emptyGuid: "00000000-0000-0000-0000-000000000000",
      token: localStorage.getItem("api_token"),
      discounts: [],
      currencies: [],
      integrations: [],
      //load
      coupon: {
        id: "00000000-0000-0000-0000-000000000000",
        name: null,
        absoluteDiscount: null,
        relativeDiscount: null,
        companyComission: null,
        discounts: [],
        validFrom: null,
        integrationId: "00000000-0000-0000-0000-000000000000",
        discountsJson: null,
        validTo: null,
        couponRelatedCategories: []
      },
      companies: [],
      // Alert
      alertType: "danger",
      message: null,
    };
  },
  methods: {
    async getIntegrations() {
      let self = this;
      self.integrations.push({
              label:"Choose",
              value: "00000000-0000-0000-0000-000000000000"
            });
     await axios
        .get(this.$apiAdress + "/v1/Integration")
        .then(function (response) {
          self.items = [];
          response.data.map(item => {
            self.integrations.push({
              label: item.name,
              value: item.id
            });
          })
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    pushRelatedCategory() {
      if (this.category != null && this.category != "") {
        this.coupon.couponRelatedCategories.push({ categoryName: this.category });
        this.category = null;
      }
    },
    eliminateRelatedCategory(category) {
      debugger;
      this.coupon.couponRelatedCategories =
        this.coupon.couponRelatedCategories.filter((x) => x.categoryName != category);
    },
    back() {
      this.$router.go(-1);
    },
    sleep(milliseconds) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    },
    get(id) {
      let self = this;
      axios
        .get(`${this.$apiAdress}/v1/Coupon/${id}`)
        .then(async function (response) {
          self.coupon = response.data;
          self.coupon.validFrom = new Date(self.coupon.validFrom)
            .toISOString()
            .split("Z")[0];
          self.coupon.validTo = new Date(self.coupon.validTo)
            .toISOString()
            .split("Z")[0];
          self.coupon.couponRelatedCategories = response.data.couponRelatedCategories;
          if (self.coupon.couponRelatedCategories == null || self.coupon.couponRelatedCategories == undefined) {
            self.coupon.couponRelatedCategories = [];
          }
          self.coupon.discounts?.map((item) => {
            document.getElementById('currency-' + item.currencyCode).checked = true;
            document.getElementById('absoluteDiscount-' + item.currencyCode).value = item.absoluteDiscount;
            document.getElementById('relativeDiscount-' + item.currencyCode).value = item.relativeDiscount;
          });
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },
    async getCurrencies() {
      let self = this;
      try {
        const response = await axios.get(this.$apiAdress + "/v1/PartnerCurrency");
        response.data.forEach((value) => {
          value.checked = false;
          self.currencies.push(value);
        });
      } catch (error) {
        self.alertType = "danger";
        setTimeout(function () {
          self.message = null;
        }, 10000);
        self.message = error;
      }
    },
    getComapniesList() {
      let self = this;
      axios
        .get(this.$apiAdress + "/v1/Company/list")
        .then(function (response) {
          self.companies = response.data;
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error;
        });
    },

    save() {
      let self = this;
      self.coupon.discounts = [];
      self.currencies.forEach((currency, index2) => {
        if (document.getElementById('currency-' + currency.currencyCode).checked == true) {
          var value = {};
          value.currencyCode = currency.currencyCode;
          value.currencyId = currency.id;
          if (document.getElementById('absoluteDiscount-' + currency.currencyCode).value != null && document.getElementById('absoluteDiscount-' + currency.currencyCode).value != "") {
            value.absoluteDiscount = document.getElementById('absoluteDiscount-' + currency.currencyCode).value;
          } else {
            value.absoluteDiscount = 0;
          }

          if (document.getElementById('relativeDiscount-' + currency.currencyCode).value != null && document.getElementById('relativeDiscount-' + currency.currencyCode).value != "") {
            value.relativeDiscount = document.getElementById('relativeDiscount-' + currency.currencyCode).value;
          } else {
            value.relativeDiscount = 0;
          }
          self.coupon.discounts.push(value);
        }
      });
      self.coupon.discountsJson = null;
      self.coupon.discountsJson = JSON.stringify(self.coupon.discounts);
      debugger;
      axios
        .post(`${this.$apiAdress}/v1/Coupon`, self.coupon)
        .then((response) => {
          self.coupon.id = response.data;
          self.alertType = "success";
          setTimeout(function () {
            self.message = null;
          }, 5000);
          self.message = "Successfully created coupon.";
        })
        .catch(function (error) {
          self.alertType = "danger";
          setTimeout(function () {
            self.message = null;
          }, 10000);
          self.message = error.response.data.message;
        });
    },
  },
  mounted: async function () {
    this.getComapniesList();
    await this.getCurrencies();
    await this.getIntegrations();

    if (this.$route.params.id != this.emptyGuid) {
      this.operationName = "Edit";
      this.get(this.$route.params.id);
    }
  },
};
</script>
